import "./App.css";
import { AuthContext } from "./context/authContext";
import { useState } from "react";
import Navigation from "./routes/navigations";
import { QueryClient, QueryClientProvider } from "react-query";
import userGlobalConfig from "./constants/globalConfig";
import { SideBarProvider } from "./context/sideBarContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem(userGlobalConfig?.Token) ? true : false
  );
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <SideBarProvider>
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
          <ToastContainer
            position="top-right"
            autoClose={1200}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Navigation />
        </AuthContext.Provider>
      </SideBarProvider>
    </QueryClientProvider>
  );
}

export default App;
