/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/sidebarwrapper";
import { useDeleteUser, useUsersList } from "../../hooks/api/getdata.hooks";
import { tableOption } from "../../seeds";
import CustomLoader from "../../components/customloader";
import { apiEndpoints } from "../../services/endPoints";
import { axiosInstance } from "../../services/axiosTokenInstance";
import Addusermodal from "../../components/addusermodal";
import DeleteModal from "../../components/deletemodal";
import { ClipLoader } from "react-spinners";
import TableRow from "./tableRow";
import { toast } from "react-toastify";



const ManageUsers = () => {
  const [countData, setCountData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [ActivePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedData,setSelectedData]=useState()

  // get user detail api
  const { data, isLoading, refetch } = useUsersList({
    limit: countData,
    page: ActivePage.toString(),
    search: searchInput,
  });

  useEffect(() => {
    setPageNumber(Math.ceil(data?.pageInfo?.totalRecords / countData));
  }, [data, isLoading]);

  // delete user api
  const { mutateAsync: deleteMutateAsync } = useDeleteUser();

  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    refetch();
  }, [countData, ActivePage]);

  // Create an array of page numbers for pagination
  function createPageArray(pages) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }
  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value) => {
    setActivePage(value);
  };

  // Handle previous page button click
  const handlePreviousPage = () => {
    if (ActivePage > 1) {
      handlePageNumber(ActivePage - 1);
    }
  };

  // Handle next page button click
  const handleNextPage = () => {
    if (ActivePage === pageNumber) {
      return;
    } else {
      handlePageNumber(ActivePage + 1);
    }
  };



  const handleDeleteUser = (data) => {
    setOpenDeleteModal(false)
    try {
      const response = deleteMutateAsync({
        firebase_uid: selectedData?.firebase_uid,
      });
      response.then((res) => refetch()).catch((error) => console.log(error));
      toast.success("User Deleted Successfully");

    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <SideBarWrapper header={"Manange Users"}>
        <CustomLoader loader={isLoading}>
          <div className="row mt-26 gy-4">
            <div className="col-lg-12">
              <div className="table-header">
                <div className="row gy-3">
                  <div className="col-md-6">
                    <div className="filter-section">
                      <div className="select-blk">
                        <select
                          className="form-select"
                          onChange={(e) => setCountData(e.target.value)}>
                          {tableOption?.map((data) => (
                            <option key={data?.id}>{data?.page}</option>
                          ))}
                        </select>
                      </div>
                      <div className="search-blk p-relative">
                        <span className="search-icon">
                          <img
                            src="/assets/images/icon/search.svg"
                            alt="Search"
                          />
                        </span>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                            setTimeout(() => {
                              refetch();
                            }, 500);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="table-btn-section">
                      <button
                        type="button"
                        className="secondary-btn me-3"
                        // data-bs-toggle="modal"
                        // data-bs-target="#addnewUser"
                        onClick={() => setOpenModal(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={16}
                          viewBox="0 0 15 16"
                          fill="none">
                          <path
                            d="M7.5 15.5C6.01664 15.5 4.56659 15.0601 3.33323 14.236C2.09986 13.4119 1.13856 12.2406 0.570907 10.8701C0.00324964 9.49968 -0.145275 7.99168 0.144114 6.53683C0.433503 5.08197 1.14781 3.7456 2.1967 2.6967C3.2456 1.64781 4.58197 0.933503 6.03683 0.644114C7.49168 0.354725 8.99968 0.50325 10.3701 1.07091C11.7406 1.63856 12.9119 2.59986 13.736 3.83323C14.5601 5.06659 15 6.51664 15 8C14.9979 9.98847 14.207 11.8949 12.8009 13.3009C11.3949 14.707 9.48847 15.4979 7.5 15.5ZM7.5 1.75C6.26387 1.75 5.0555 2.11656 4.02769 2.80332C2.99988 3.49008 2.1988 4.46619 1.72576 5.60823C1.25271 6.75027 1.12894 8.00694 1.3701 9.21932C1.61125 10.4317 2.20651 11.5453 3.08059 12.4194C3.95466 13.2935 5.06831 13.8888 6.28069 14.1299C7.49307 14.3711 8.74974 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23614 13.75 8C13.7482 6.34296 13.0891 4.7543 11.9174 3.5826C10.7457 2.41089 9.15705 1.75182 7.5 1.75Z"
                            fill="#718096"
                          />
                          <path
                            d="M8.125 4.25H6.875V11.75H8.125V4.25Z"
                            fill="#718096"
                          />
                          <path
                            d="M11.25 7.375H3.75V8.625H11.25V7.375Z"
                            fill="#718096"
                          />
                        </svg>
                        add new user
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-main-blk bg-white table-shadow spacer-y24 p-relative">
              {data?.pageInfo.totalRecords > 0?<table className="table mb-0">
                <thead>
                  <tr>
                    
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Account Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.users?.map((data) =><TableRow refetch={refetch} setOpenDeleteModal={setOpenDeleteModal} setSelectedData={setSelectedData} data={data}/> )}
                </tbody>
              </table>:<div style={{width:"100%", display:"flex", justifyContent:"center"}}><img src="assets/images/temp-images/no record found.png" height="100px" width="200px" alt=""/></div>}

                <div className="table-footer">
                  <div className="row gy-2 align-items-center">
                    <div className="col-md-4">
                      <div className="showing-result-blk">
                        <p className="mb-0">
                          {/* Total records : {data?.pageInfo?.totalRecords} */}
                          {data?.pageInfo.totalRecords > 0 ? `Showing 1 to ${countData} of ${data?.pageInfo.totalRecords} entries`: ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pagination-blk">
                        <ul className="pagination mb-0 justify-content-sm-end">
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={handlePreviousPage}>
                              <i className="fa-solid fa-chevron-left" />
                            </a>
                          </li>
                          {myPageArray?.map((data) => (
                            <li key={data.id} className="page-item">
                              <a
                                onClick={() => handlePageNumber(data)}
                                className={
                                  ActivePage === data
                                    ? "page-link active"
                                    : "page-link "
                                }>
                                {data}
                              </a>
                            </li>
                          ))}
                          <li className="page-item">
                            <a className="page-link" onClick={handleNextPage}>
                              <i className="fa-solid fa-chevron-right" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomLoader>
      </SideBarWrapper>
      {/* Add New User modal */}
      <Addusermodal
        openModal={openModal}
        setOpenModal={setOpenModal}
        refetch={refetch}
      />
    <DeleteModal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} handleDelete={handleDeleteUser}/>
    </div>
  );
};

export default ManageUsers;
