import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { initialValues, validationSchema } from "../../validations/schema";
import { AuthContext } from "../../context/authContext";
import userGlobalConfig from "../../constants/globalConfig";
import { useUserLogin } from "../../hooks/api/auth.hooks";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useUserLogin();
  const { setIsAuthenticated } = React.useContext(AuthContext);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async () => {
        try {
          const response = await mutateAsync({
            email: values?.email,
            password: values?.password,
          });

          if (response) {
            localStorage.setItem(userGlobalConfig?.Token, response?.token);
            navigate("/dashboard");
            setIsAuthenticated(true);
            toast.success("Login Successfully");
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
        }
      },
    });

  return (
    <div>
      <section className="login-section">
        <div className="container-fluid g-0">
          <div className="row g-0">
            <div className="col-md-6 d-none d-md-block">
              <div className="login-img-blk">
                <img
                  src="/assets/images/temp-images/nextmil-banner.png"
                  alt="Banner"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-main-blk">
                <div className="login-form-blk">
                  <h1>Log in to Nexmil</h1>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="p-relative">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="input-icon">
                          <img src="/assets/images/icon/mail.svg" alt="Email" />
                        </span>
                      </div>
                      {touched.email && errors.email && (
                        <div class="error">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="p-relative">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="input-icon">
                          <img
                            src="/assets/images/icon/lock.svg"
                            alt="Password"
                          />
                        </span>
                        <span id="eye">
                          <i
                            style={{ fontSize: "15px" }}
                            className={`fa ${
                              passwordVisible ? "fa-eye" : "fa-eye-slash"
                            }`}
                            id="eyeIcon"
                            onClick={togglePasswordVisibility}
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      {touched.password && errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                    <div className="forgot-password-blk text-end">
                      <a
                        href="#!"
                        className="forgot-password d-inline-block ms-auto">
                        forgot password?
                      </a>
                    </div>
                    <div className="login-btn-blk">
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn primary-btn btn-style">
                        {isLoading ? (
                          <ClipLoader size={20} color="#000" />
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
