/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/sidebarwrapper";
import { useOrdersList, useRedeemRequest } from "../../hooks/api/getdata.hooks";
import { tableOption } from "../../seeds";

const RedeemRequests = () => {
  const [countData, setCountData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [ActivePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState();
  const [newStatus, setNewStatus] = useState();

  const { data, isLoading, refetch } = useRedeemRequest({
    limit: countData,
    page: ActivePage.toString(),
    // search: searchInput,
  });

  useEffect(() => {
    if (data) setPageNumber(Math.ceil(data?.pageInfo.totalRecords / countData));
  }, [data]);

  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    refetch();
  }, [countData, ActivePage]);
  // Create an array of page numbers for pagination
  function createPageArray(pages) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);
  // pagination
  const handlePageNumber = (value) => {
    setActivePage(value);
  };
  // Handle previous page button click
  const handlePreviousPage = () => {
    if (ActivePage > 1) {
      handlePageNumber(ActivePage - 1);
    }
  };

  // Handle next page button click
  const handleNextPage = () => {
    if (ActivePage === pageNumber) {
      return;
    } else {
      handlePageNumber(ActivePage + 1);
    }
  };

  return (
    <SideBarWrapper header={"Redeem Request"}>
      <div className="row mt-26 gy-4">
        <div className="col-lg-12">
          <div className="table-header">
            <div className="row gy-3">
              <div className="col-md-6">
                <div className="filter-section">
                  <div className="select-blk">
                    <select
                      className="form-select"
                      onChange={(e) => setCountData(e.target.value)}>
                      {tableOption?.map((data) => (
                        <option key={data?.id}>{data?.page}</option>
                      ))}
                    </select>
                  </div>
                  <div className="search-blk p-relative">
                    <span className="search-icon">
                      <img src="/assets/images/icon/search.svg" alt="Search" />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                        setTimeout(() => {
                          refetch();
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
          <div className="table-main-blk bg-white table-shadow spacer-y24 p-relative">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>
                    <div className="th-flex">
                      <span>Name</span>
                    </div>
                  </th>

                  <th>Product Request</th>
                  <th>Coin Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.redeemRequests?.map((items) => (
                  <tr>
                    <td>
                      <div className="td-flex">
                        <span className="profile-span">
                          <span className="profile-user-name">
                            {items.requestRaisedBy}
                          </span>
                        </span>
                      </div>
                    </td>

                    <td>{items.productName}</td>
                    <td>{items.price}</td>
                    <td>
                      <span className="ad-status">
                        <select
                          className="form-select approve-state"
                          value={items.status}
                          onChange={(e) => {
                            const newStatusValue = e.target.value;
                            setNewStatus(newStatusValue);
                          }}>
                          <option value="PENDING">PENDING</option>
                          <option value="DECLINED">DECLINED</option>
                        </select>
                      </span>
                    </td>

                    <td>
                      <button
                        className="edit-icon-btn"
                        // onClick={() => handleDeleteUser(data)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={16}
                          viewBox="0 0 14 16"
                          fill="none">
                          <path
                            d="M13.4909 2.96062H10.7203V1.12844C10.7203 0.991685 10.666 0.860533 10.5693 0.763835C10.4726 0.667137 10.3414 0.612812 10.2047 0.612812H4.45031C4.31356 0.612812 4.18241 0.667137 4.08571 0.763835C3.98901 0.860533 3.93469 0.991685 3.93469 1.12844V2.96062H1.16406C1.02731 2.96062 0.896159 3.01495 0.799461 3.11165C0.702762 3.20835 0.648438 3.3395 0.648438 3.47625C0.648438 3.613 0.702762 3.74415 0.799461 3.84085C0.896159 3.93755 1.02731 3.99187 1.16406 3.99187H1.8275V13.5034C1.82841 14.0034 2.02764 14.4825 2.38146 14.8357C2.73528 15.1888 3.21477 15.3872 3.71469 15.3872H10.9678C11.463 15.38 11.9354 15.1784 12.2833 14.826C12.6312 14.4736 12.8267 13.9986 12.8275 13.5034V3.99187H13.4772C13.6139 3.9937 13.7458 3.94112 13.8438 3.84571C13.9418 3.7503 13.9979 3.61988 13.9997 3.48312C14.0015 3.34637 13.9489 3.2145 13.8535 3.11651C13.7581 3.01852 13.6277 2.96245 13.4909 2.96062ZM4.96594 1.64406H9.68906V2.96062H4.96594V1.64406ZM11.7963 13.5034C11.7953 13.7298 11.7048 13.9467 11.5443 14.1065C11.3839 14.2662 11.1667 14.3559 10.9403 14.3559H3.70094C3.47691 14.3523 3.26323 14.261 3.10577 14.1017C2.94831 13.9423 2.85962 13.7275 2.85875 13.5034V3.99187H11.7963V13.5034Z"
                            fill="#718096"
                          />
                          <path
                            d="M5.95264 5.89625C5.81588 5.89625 5.68473 5.95057 5.58804 6.04727C5.49134 6.14397 5.43701 6.27512 5.43701 6.41187V11.9428C5.43701 12.0796 5.49134 12.2107 5.58804 12.3074C5.68473 12.4041 5.81588 12.4584 5.95264 12.4584C6.08939 12.4584 6.22054 12.4041 6.31724 12.3074C6.41394 12.2107 6.46826 12.0796 6.46826 11.9428V6.41187C6.46826 6.27512 6.41394 6.14397 6.31724 6.04727C6.22054 5.95057 6.08939 5.89625 5.95264 5.89625Z"
                            fill="#718096"
                          />
                          <path
                            d="M8.70264 5.89625C8.56588 5.89625 8.43473 5.95057 8.33803 6.04727C8.24134 6.14397 8.18701 6.27512 8.18701 6.41187V11.9428C8.18701 12.0796 8.24134 12.2107 8.33803 12.3074C8.43473 12.4041 8.56588 12.4584 8.70264 12.4584C8.83939 12.4584 8.97054 12.4041 9.06724 12.3074C9.16394 12.2107 9.21826 12.0796 9.21826 11.9428V6.41187C9.21826 6.27512 9.16394 6.14397 9.06724 6.04727C8.97054 5.95057 8.83939 5.89625 8.70264 5.89625Z"
                            fill="#718096"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              <div className="row gy-2 align-items-center">
                <div className="col-md-4">
                  <div className="showing-result-blk">
                    {data?.pageInfo.totalRecords > 1
                      ? `Showing 1 ${countData ? ` to ${countData}` : ""} of ${
                          data?.pageInfo.totalRecords
                        } entries`
                      : ""}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="pagination-blk">
                    <ul className="pagination mb-0 justify-content-sm-end">
                      <li className="page-item">
                        <a className="page-link" onClick={handlePreviousPage}>
                          <i className="fa-solid fa-chevron-left" />
                        </a>
                      </li>

                      {myPageArray?.map((data) => (
                        <li key={data.id} className="page-item">
                          <a
                            onClick={() => handlePageNumber(data)}
                            className={
                              ActivePage === data
                                ? "page-link active"
                                : "page-link "
                            }>
                            {data}
                          </a>
                        </li>
                      ))}

                      <li className="page-item">
                        <a className="page-link" onClick={handleNextPage}>
                          <i className="fa-solid fa-chevron-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default RedeemRequests;
