import * as Yup from "yup";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .required("Password is required"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  accountType: "",
};

const validationSchemaRegister = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .required("Password is required"),
  phoneNumber: Yup.string().required("Mobile number required"),
  accountType: Yup.string().required("Account type is required"),
});

const initialValuesProduct = {
  name: "",
  price: "",
  quantity: "",
  image: "",
};

const validationSchemaProduct = Yup.object().shape({
  name: Yup.string().required("Product name is required"),
  price: Yup.number()
    .typeError("Price must be a number")
    .positive("Price must be a positive number")
    .required("Price is required"),
  quantity: Yup.number()
    .typeError("Quantity must be a number")
    .integer("Quantity must be an integer")
    .positive("Quantity must be a positive number")
    .required("Quantity is required"),
  image: Yup.string().required("Image URL is required"),
});



const initialValuesRaferrals = {
  firstName: "",
  lastName: "",
  phone: "",
  notes: "",
  coinsEarned: "",
  status: "",
  referredBy: "",
};

const validationSchemaRaferrals = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  notes: Yup.string(),
  coinsEarned: Yup.string().required("Coins earned is required"),
  status: Yup.string().required("Status is required"),
  referredBy: Yup.string().required("Referred by is required"),
});


export {
  initialValues,
  validationSchema,
  initialValuesRegister,
  validationSchemaRegister,
  initialValuesProduct,
  validationSchemaProduct,
  initialValuesRaferrals,
  validationSchemaRaferrals
};
