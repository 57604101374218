import { useFormik } from "formik";
import React from "react";
import {
 initialValuesRaferrals,
  validationSchemaRaferrals,
} from "../validations/schema";
import { useNewRaferral, useNewUser, useRolesTypeList } from "../hooks/api/getdata.hooks";
import { NumbersOnly } from "../utils/helpers";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const AddRaferralModal = ({ openModal, setOpenModal, refetch,userData }) => {
  const { mutateAsync: raferralMutateAsync, isLoading: isRaferralLoading } =
  useNewRaferral();
  const { data: getRolesData } = useRolesTypeList();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValuesRaferrals,
    validationSchema: validationSchemaRaferrals,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await raferralMutateAsync({
            firstName: values?.firstName,
            lastName: values?.lastName,
            phone:values?.phone,
            notes:values?.notes,
            coinsEarned:Number(values?.coinsEarned),
            status:values?.status,
            referredBy:values?.referredBy
        });

        if (response) {
          refetch();
          resetForm();
          setOpenModal(false);
          toast.success(response?.message);
        }
      } catch (error) {
        console.log("err");
        setOpenModal(false);
      }
    },
  });
  return (
    <div
    className={
        openModal
          ? "modal fade common-modal show d-block "
          : "modal fade common-modal d-none "
      }
        id="addnewReferral"
        tabIndex={-1}
        aria-labelledby="addnewReferralLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="common-form-wrapper">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpenModal(false)}
                />
                <div className="modal-header-blk">
                  <h2>Add New Referrals</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="common-form-fields">
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched.firstName && errors.firstName && (
                    <div class="error">{errors.firstName}</div>
                  )}
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched.lastName && errors.lastName && (
                    <div class="error">{errors.lastName}</div>
                  )}
                    <div className="common-fields">
                      <input
                        type="text"
                        onKeyPress={NumbersOnly}
                        className="form-control"
                        placeholder="Phone Number"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched.phone && errors.phone && (
                    <div class="error">{errors.phone}</div>
                  )}
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Notes"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched.notes && errors.notes && (
                    <div class="error">{errors.notes}</div>
                  )}
                   
                      <div className="common-fields">
                      <select className="form-select"
                      name="status"
                      onChange={handleChange}>
                        <option>Status</option>
                        <option>PENDING</option>
                        <option>ACCEPTED</option>
                     
                      </select>
                    </div>
                    {touched.status && errors.status && (
                    <div class="error">{errors.status}</div>
                  )}
                    <div className="common-fields">
                      <select className="form-select"
                       name="referredBy"
                       value={values?.referredBy}
                       onChange={handleChange}>
                        <option>Attribute Referrals to</option>
                        {userData?.users?.map((data) => (<option value={data?.firebase_uid}>{data?.firstName}</option>))}
                      </select>
                    </div>
                    {touched.referredBy && errors.referredBy && (
                    <div class="error">{errors.referredBy}</div>
                  )}
                    <div className="common-fields">
                      <select className="form-select"
                       name="coinsEarned"
                       onChange={handleChange}>
                        <option>Coin rewards</option>
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                      </select>
                    </div>
                    {touched.coinsEarned && errors.coinsEarned && (
                    <div class="error">{errors.coinsEarned}</div>
                  )}
                  </div>
                  <div className="common-form-button">
                    <button className="primary-btn"  type="submit">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    // <div
    //   className={
    //     openModal
    //       ? "modal fade common-modal show d-block "
    //       : "modal fade common-modal d-none "
    //   }
    //   id="addnewUser"
    //   tabIndex={-1}
    //   aria-labelledby="addnewUserLabel"
    //   aria-hidden="true">
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content">
    //       <div className="modal-body">
    //         <div className="common-form-wrapper">
    //           <button
    //             type="button"
    //             className="btn-close"
    //             // data-bs-dismiss="modal"
    //             // aria-label="Close"
    //             onClick={() => setOpenModal(false)}
    //           />
    //           <div className="modal-header-blk">
    //             <h2>Add New User</h2>
    //           </div>
    //           <form onSubmit={handleSubmit}>
    //             <div className="common-form-fields">
    //               <div className="common-fields">
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="First Name"
    //                   name="firstName"
    //                   value={values.firstName}
    //                   onChange={handleChange}
    //                   onBlur={handleBlur}
    //                 />
    //               </div>
    //               {touched.firstName && errors.firstName && (
    //                 <div class="error">{errors.firstName}</div>
    //               )}
    //               <div className="common-fields">
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="Last Name"
    //                   name="lastName"
    //                   value={values.lastName}
    //                   onChange={handleChange}
    //                   onBlur={handleBlur}
    //                 />
    //               </div>
    //               {touched.lastName && errors.lastName && (
    //                 <div class="error">{errors.lastName}</div>
    //               )}
    //               <div className="common-fields">
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="email"
    //                   name="email"
    //                   value={values.email}
    //                   onChange={handleChange}
    //                   onBlur={handleBlur}
    //                 />
    //               </div>
    //               {touched.email && errors.email && (
    //                 <div class="error">{errors.email}</div>
    //               )}
    //               <div className="common-fields">
    //                 <input
    //                   type="password"
    //                   className="form-control"
    //                   placeholder="Password"
    //                   name="password"
    //                   value={values.password}
    //                   onChange={handleChange}
    //                   onBlur={handleBlur}
    //                 />
    //               </div>
    //               {touched.password && errors.password && (
    //                 <div class="error">{errors.password}</div>
    //               )}
    //               <div className="common-fields">
    //                 <input
    //                   onKeyPress={NumbersOnly}
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="phone number"
    //                   name="phoneNumber"
    //                   value={values.phoneNumber}
    //                   onChange={handleChange}
    //                   onBlur={handleBlur}
    //                 />
    //               </div>
    //               {touched.phoneNumber && errors.phoneNumber && (
    //                 <div class="error">{errors.phoneNumber}</div>
    //               )}
    //               <div className="common-fields">
    //                 <select
    //                   className="form-select"
    //                   name="accountType"
    //                   onChange={handleChange}>
    //                   <option>Account Type</option>
    //                   {getRolesData?.map((item) => {
    //                     return <option>{item}</option>;
    //                   })}
    //                 </select>
    //               </div>
    //               {touched.accountType && errors.accountType && (
    //                 <div class="error">{errors.accountType}</div>
    //               )}
    //             </div>
    //             <div className="common-form-button">
    //               <button
    //                 disabled={isRegisterLoading}
    //                 type="submit"
    //                 className="primary-btn">
    //                 {isRegisterLoading ? <ClipLoader size={15} /> : "Save"}
    //               </button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AddRaferralModal;
