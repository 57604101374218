import React from "react";
import SideBarHeader from "./sidebarheader";
import { useSideBarContext } from "../../context/sideBarContext";

const SideBarWrapper = ({ children, header }) => {
  const { openSidebar, toggleSidebar } = useSideBarContext();

  return (
    <div
      className={
        openSidebar || window.innerWidth < 992
          ? "main-wrapper main-width"
          : "main-wrapper"
      }>
      <div className="content-wrapper">
        <div
          className={
            openSidebar && window.innerWidth <= 992
              ? "header-blk py-2"
              : "header-blk py-2 d-none"
          }>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-header">
                  <button
                    className="btn-box"
                    id="optBtn"
                    onClick={toggleSidebar}>
                    <span className="btn_bar btn_width top" />
                    <span className="btn_bar" />
                    <span className="btn_bar btn_width bottom" />
                  </button>
                  <span className="company-name">
                    <img
                      width={25}
                      src="assets/images/logo/nexmil-logo-yl.png"
                      alt="logo"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="common-section spacer">
          <div className="container">
            <SideBarHeader title={header} />
            {children}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SideBarWrapper;
