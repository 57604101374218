import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const Utils = {
  months: ({ count }) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ];
    return monthNames.slice(0, count);
  },
  CHART_COLORS: {
    yellow: "#dbb509",
  },
};

const Linechart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, []);

  const labels = Utils.months({ count: 7 });

  // Replace this array with your static values
  const staticData = [
    15, 16, 17, 18, 19, 22, 23, 25, 20, 30, 35, 28, 22, 12, 11,
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: staticData,
        backgroundColor: Utils.CHART_COLORS.yellow,
        barThickness: 8,
        borderRadius: 10,
        borderSkipped: {
          bottom: 0,
        },
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
      },
    },
  };

  return <canvas ref={chartRef} width="400" height="260"></canvas>;
};

export default Linechart;
