import { axiosInstance } from "../../services/axiosTokenInstance";
import { useMutation, useQuery } from "react-query";
import { apiEndpoints } from "../../services/endPoints";

const fetchOrders = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getOrders,
    headers: { "Content-Type": "application/json" },
    params: queryParams, // Pass the query parameters here
  });
  return data;
};

const useOrdersList = (queryParams) => {
  return useQuery({
    queryKey: ["materialsList"],
    queryFn: () => fetchOrders(queryParams), // Pass the query parameters here
  });
};

//get user detail api
const fetchUsers = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getAllUsers,
    headers: { "Content-Type": "application/json" },
    params: queryParams,
  });
  return data;
};

const useUsersList = (queryParams) => {
  return useQuery({
    queryKey: ["useUsersList"],
    queryFn: () => fetchUsers(queryParams),
  });
};

// delete user api
const fetchDeleteUserDetail = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().deleteUser,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useDeleteUser = () => {
  return useMutation({
    mutationKey: ["useDeleteUser"],
    mutationFn: (params) => fetchDeleteUserDetail(params),
  });
};
// add new user api
const fetchAddUser = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().registerUser,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useNewUser = () => {
  return useMutation({
    mutationKey: ["useNewUser"],
    mutationFn: (params) => fetchAddUser(params),
  });
};

//get accont type detail api
const fetchRolesType = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getRoles,
    headers: { "Content-Type": "application/json" },
    params: queryParams,
  });
  return data;
};

const useRolesTypeList = (queryParams) => {
  return useQuery({
    queryKey: ["useRolesTypeList"],
    queryFn: () => fetchRolesType(queryParams),
  });
};
//get products detail api
const fetchProducts = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getProducts,
    headers: { "Content-Type": "application/json" },
    params: queryParams,
  });
  return data;
};

const useProductList = (queryParams) => {
  return useQuery({
    queryKey: ["useProductList"],
    queryFn: () => fetchProducts(queryParams),
  });
};

// add new product api
const fetchAddProduct = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().createProduct,
    data: params,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const useNewProduct = () => {
  return useMutation({
    mutationKey: ["useNewProduct"],
    mutationFn: (params) => fetchAddProduct(params),
  });
};
// delete product api
const fetchDeleteProduct = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().deleteProducts,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useDeleteProduct = () => {
  return useMutation({
    mutationKey: ["useDeleteProduct"],
    mutationFn: (params) => fetchDeleteProduct(params),
  });
};
//get referal list api
const fetchReferals = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getReferrals,
    headers: { "Content-Type": "application/json" },
    params: queryParams,
  });
  return data;
};

const useReferalList = (queryParams) => {
  return useQuery({
    queryKey: ["useReferalList"],
    queryFn: () => fetchReferals(queryParams),
  });
};

// add new referral
const fetchAddRefferal = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().createReferral,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useNewRaferral = () => {
  return useMutation({
    mutationKey: ["useNewRaferral"],
    mutationFn: (params) => fetchAddRefferal(params),
  });
};

// deleteReferral
const fetchDeleteReferral = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().deleteReferral,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useDeleteReferral = () => {
  return useMutation({
    mutationKey: ["useDeleteReferral"],
    mutationFn: (params) => fetchDeleteReferral(params),
  });
};

//get redeem request api
const fetchRedeemRequest = async (queryParams) => {
  const { data } = await axiosInstance({
    method: "get",
    url: apiEndpoints().getRedeemRequest,
    headers: { "Content-Type": "application/json" },
    params: queryParams,
  });
  return data;
};

const useRedeemRequest = (queryParams) => {
  return useQuery({
    queryKey: ["useRedeemRequest"],
    queryFn: () => fetchRedeemRequest(queryParams),
  });
};

export {
  useUsersList,
  useOrdersList,
  useDeleteUser,
  useNewUser,
  useRolesTypeList,
  useProductList,
  useNewProduct,
  useDeleteProduct,
  useReferalList,
  useNewRaferral,
  useDeleteReferral,
  useRedeemRequest
};
