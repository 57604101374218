/* eslint-disable import/no-anonymous-default-export */

import axios from "axios";
import userGlobalConfig from "../constants/globalConfig.js";

const token = localStorage.getItem(userGlobalConfig.Token);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    Authorization: `Bearer ${token}`,
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // if token required then read the login token (ACCESS TOKEN) and pass that to headers
    const token = await localStorage.getItem(userGlobalConfig.Token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log("config ", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("respose from token instance ", response);
    return response;
  },
  (error) => {
    if (error.response?.status === 403 || 401) {
      console.log("error from token instance ", error.response);
      localStorage.removeItem(userGlobalConfig.Token);
      window.location.reload();
      // if api return 401 then we will logout the user
      // DeviceEventEmitter.emit('AuthorizationExpired', 'ERR_USER_ROLE_CHANGED')
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
