const tableOption = [
  {
    id: "1",
    page: "10",
  },
  {
    id: "2",
    page: "15",
  },
  {
    id: "3",
    page: "25",
  },
  {
    id: "4",
    page: "50",
  },
];

export { tableOption };
