import React from "react";

const SideBarHeader = ({ title }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="top-heading-blk">
          <div className="heading">
            <h1>{title}</h1>
            <p>Hi, Admin</p>
          </div>
          <div className="user-info-blk">
            {/* <span className="userinfo">
              Hello, <span className="username">Samantha</span>
            </span> */}
            {/* <span className="userimg">
              <img src="/assets/images/temp-images/avatar.png" alt="User" />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarHeader;
