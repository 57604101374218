import React, { useContext } from "react";
import { HashRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import Dashboard from "../screens/dashboard";
import Login from "../screens/auth/login";
import { AuthContext } from "../context/authContext";
import Sidebar from "../components/sidebar";
import ManageProducts from "../screens/manageproducts";
import ManageUsers from "../screens/manageusers";
import RedeemRequests from "../screens/redeemrequest";
import Referals from "../screens/referals";
import Settings from "../screens/settings";

const Navigation = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const Pagelayout = () => {
    return (
      <>
        <Sidebar />
        <Outlet />
      </>
    );
  };
  const Authlayout = () => {
    return <Outlet />;
  };
  return (
    <HashRouter>
      <Routes>
        {!isAuthenticated ? (
          <Route element={<Authlayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
        ) : (
          <Route element={<Pagelayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manageproducts" element={<ManageProducts />} />
            <Route path="/manageusers" element={<ManageUsers />} />
            <Route path="/redeemrequests" element={<RedeemRequests />} />
            <Route path="/referals" element={<Referals />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        )}
        <Route
          path="*"
          element={<Navigate to={!isAuthenticated ? "/login" : "/dashboard"} />}
        />
      </Routes>
    </HashRouter>
  );
};
export default Navigation;
