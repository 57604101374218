import React from "react";

const CustomLoader = ({ loader, children }) => {
  if (loader) {
    return (
      <div class="box">
        <div class="loader"></div>
      </div>
    );
  }
  return children;
};

export default CustomLoader;
