export const apiEndpoints = () => ({
  login: "login",
  getAllUsers: "getAllUsers",
  disableUsers: "disableUser",
  enableUsers: "enableUser",
  deleteUser: "deleteUser",
  registerUser: "register",
  getRoles: "getRoles",
  getProducts: "getProducts",
  createProduct: "createProduct",
  deleteProducts:"deleteProduct",
  getReferrals:"getReferrals",
  createReferral:"createReferral",
  deleteReferral:"deleteReferral",
  getRedeemRequest:"getRedeemRequest",

  getData: "admin/auth/users?page=1&limit=10",
  getOrder: "admin/auth/order",
  getOrders: "admin/auth/orders",
});
