import React from "react";
import SideBarWrapper from "../../components/sidebarwrapper";

const Settings = () => {
  return (
    <SideBarWrapper header={"Settings"}>
      <div className="row mt-26 g-4">
        <div className="col-md-6 col-lg-6">
          <div className="setting-cards bg-white shadow-2 spacer-24">
            <div className="setting-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Store Information</h2>
            </div>
            <div className="wrapper-setting">
              <h3>Printful Account</h3>
              <div className="setting-fields">
                <h3>Store Name</h3>
                <div className="setting-flex-blk">
                  <p>NexMil</p>
                </div>
              </div>
              <div className="setting-fields">
                <h3>API Key</h3>
                <div className="setting-flex-blk">
                  <div className="input-field">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="345678112222hhhhkl"
                    />
                  </div>
                  <button className="primary-btn">Connected</button>
                </div>
              </div>
              <div className="setting-fields">
                <h3>Sync</h3>
                <div className="setting-flex-blk">
                  <button className="primary-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none">
                      <path
                        d="M1.00975 4.11322L1.01035 4.11382L4.45695 4.11322V2.78122H2.62695C3.47655 1.89062 4.67195 1.33322 5.99995 1.33322C8.57675 1.33322 10.6665 3.42242 10.6665 5.99982H11.9999C11.9999 2.68602 9.31375 -0.000183105 5.99995 -0.000183105C4.30015 -0.000183105 2.76815 0.710017 1.67715 1.84622L1.67635 1.67847e-05H0.34375V3.44662C0.34375 3.81522 0.64195 4.11322 1.00975 4.11322Z"
                        fill="black"
                      />
                      <path
                        d="M10.9896 7.8868V7.8862L7.543 7.8868V9.2188H9.3724C8.5234 10.1094 7.3282 10.6666 6 10.6666C3.4232 10.6666 1.3334 8.5776 1.3334 6H0C0 9.3138 2.6862 12 6 12C7.6992 12 9.2318 11.2896 10.3228 10.1538V12H11.6562V8.5534C11.6562 8.185 11.358 7.8868 10.9896 7.8868Z"
                        fill="black"
                      />
                    </svg>
                    Sync Store
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="setting-cards bg-white shadow-2 spacer-24">
            <div className="setting-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Store Payment Information</h2>
            </div>
            <div className="wrapper-setting">
              <h3>Printful Account</h3>
              <div className="setting-fields">
                <h3>Name on card</h3>
                <div className="setting-flex-blk">
                  <div className="input-field">
                    <input type="text" className="form-control" defaultValue />
                  </div>
                </div>
              </div>
              <div className="setting-fields">
                <h3>Credit Card</h3>
                <div className="setting-flex-blk">
                  <div className="input-field">
                    <input type="text" className="form-control" defaultValue />
                  </div>
                </div>
              </div>
              <div className="setting-fields">
                <h3>Exp Date</h3>
                <div className="setting-flex-blk">
                  <div className="input-field">
                    <input type="text" className="form-control" defaultValue />
                  </div>
                </div>
              </div>
              <div className="setting-fields">
                <h3>Code</h3>
                <div className="setting-flex-blk">
                  <div className="input-field">
                    <input type="text" className="form-control" defaultValue />
                  </div>
                </div>
              </div>
              <div className="setting-fields justify-content-end">
                <div className="setting-flex-blk">
                  <button className="primary-btn">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default Settings;
