/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Bargraph from "../../components/bargraph";
import SideBarWrapper from "../../components/sidebarwrapper";
import Linechart from "../../components/linechart";

const Dashboard = () => {
  return (
    <SideBarWrapper header={"Dashboard"}>
      <div className="row mt-26 g-4">
        <div className="col-md-6 col-lg-6">
          <div className="graph-cards bg-white shadow-2 spacer-24">
            <div className="graph-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Monthly Referrals</h2>
              <div className="select-field-wrapper">
                <div className="select-field-inner-blk p-relative">
                  <select className="form-control">
                    <option>Day</option>
                    <option>Week</option>
                    <option selected>Month</option>
                    <option>Year</option>
                  </select>
                  <span className="select-arrow">
                    <img
                      src="/assets/images/icon/selection-arrow.svg"
                      alt="arrow"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="wrapper-graph">
              <Bargraph />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="graph-cards bg-white shadow-2 spacer-24">
            <div className="graph-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Total Users</h2>
              <div className="select-field-wrapper">
                <div className="select-field-inner-blk p-relative">
                  <select className="form-control">
                    <option>Day</option>
                    <option>Week</option>
                    <option selected>Month</option>
                    <option>Year</option>
                  </select>
                  <span className="select-arrow">
                    <img
                      src="/assets/images/icon/selection-arrow.svg"
                      alt="arrow"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="wrapper-graph">
              <Linechart />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="graph-cards bg-white shadow-2 spacer-24">
            <div className="graph-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Total Item Redeemeds</h2>
              <a href="javascript:void(0)" className="viewall-item-list">
                View all
              </a>
            </div>
            <div className="total-item-redeemed">
              <ul>
                <li>
                  <span className="redeemed-item-list">
                    <span className="redeemed-item-detail">
                      <span className="redeemed-item-img" />
                      <span className="redeemed-item-text">
                        Toronto maple Least Jersey
                      </span>
                    </span>
                    <span className="redeemed-item-value">555</span>
                  </span>
                </li>
                <li>
                  <span className="redeemed-item-list">
                    <span className="redeemed-item-detail">
                      <span className="redeemed-item-img" />
                      <span className="redeemed-item-text">
                        Toronto maple Least Jersey
                      </span>
                    </span>
                    <span className="redeemed-item-value">345</span>
                  </span>
                </li>
                <li>
                  <span className="redeemed-item-list">
                    <span className="redeemed-item-detail">
                      <span className="redeemed-item-img" />
                      <span className="redeemed-item-text">
                        Toronto maple Least Jersey
                      </span>
                    </span>
                    <span className="redeemed-item-value">755</span>
                  </span>
                </li>
                <li>
                  <span className="redeemed-item-list">
                    <span className="redeemed-item-detail">
                      <span className="redeemed-item-img" />
                      <span className="redeemed-item-text">
                        Toronto maple Least Jersey
                      </span>
                    </span>
                    <span className="redeemed-item-value">585</span>
                  </span>
                </li>
                <li>
                  <span className="redeemed-item-list">
                    <span className="redeemed-item-detail">
                      <span className="redeemed-item-img" />
                      <span className="redeemed-item-text">
                        Toronto maple Least Jersey
                      </span>
                    </span>
                    <span className="redeemed-item-value">56</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-7">
          <div className="graph-cards bg-white shadow-2 spacer-24">
            <div className="graph-cards-top-blk p-relative">
              <h2 className="txt-heading-clr">Total Products Redeemeds</h2>
              <div className="select-field-wrapper">
                <div className="select-field-inner-blk p-relative">
                  <select className="form-control">
                    <option>Day</option>
                    <option>Week</option>
                    <option selected>Month</option>
                    <option>Year</option>
                  </select>
                  <span className="select-arrow">
                    <img
                      src="/assets/images/icon/selection-arrow.svg"
                      alt="arrow"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="wrapper-graph">
              <img
                src="/assets/images/temp-images/total-product-graph.png"
                alt="chart"
              />
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default Dashboard;
