import { axiosInstance } from "../../services/axiosTokenInstance";
import { apiEndpoints } from "../../services/endPoints";
import { useMutation } from "react-query";

const fetchUserLogin = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: apiEndpoints().login,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useUserLogin = () => {
  return useMutation({
    mutationKey: ["useUserLogin"],
    mutationFn: (params) => fetchUserLogin(params),
  });
};

export { useUserLogin };
