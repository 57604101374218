const paswordValidatorRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const userNameValidatorRegex = /^[a-zA-Z ]\S\w*$/;

const consoleLogProvider = (identifier, value, typeToConver) =>
  typeToConver === 1
    ? console.log(`${identifier}  >>>> `, JSON.stringify(value))
    : console.log(`${identifier}  >>>> `, value);

const NumbersOnly = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

const NumbersAndDecimalOnly = (event) => {
  const input = event.target.value;

  // Allow numbers (0-9) and the decimal point (.)
  if (!/^\d*\.?\d{0,2}$/.test(input + event.key)) {
    event.preventDefault();
  }
};

const InputOnly = (event) => {
  if (!/^[A-Za-z]+$/.test(event.key)) {
    event.preventDefault();
  }
};

export {
  consoleLogProvider,
  NumbersOnly,
  NumbersAndDecimalOnly,
  InputOnly,
  paswordValidatorRegex,
  userNameValidatorRegex,
};
