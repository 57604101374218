/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/sidebarwrapper";
import { tableOption } from "../../seeds";
import { useDeleteReferral, useOrdersList, useReferalList, useUsersList } from "../../hooks/api/getdata.hooks";
import DeleteModal from "../../components/deletemodal";
import AddRaferralModal from "../../components/addRaferralModal";

const Referals = () => {
  const [countData, setCountData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [ActivePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState();
  const [openDeleteModal,setOpenDeleteModal] =useState(false)
  const [selectedData,setSelectedData]=useState()
  const [openModal,setOpenModal]=useState(false)

  //get user list api
  const { data:userData, refetch:userRefetch } = useUsersList({
    limit: 15,
    page: 1,
    search: searchInput,
  });
// get refferal api
  const { data, isLoading, refetch } = useReferalList({
    limit: countData,
    page: ActivePage.toString(),
    search: searchInput,
  });


  useEffect(() => {
    if (data) setPageNumber(Math.ceil(data?.pageInfo.totalRecords / countData));
  }, [data]);


  useEffect(() => {
    handlePageNumber(1);
  }, [countData]);

  // Refresh user data when count or active page changes
  useEffect(() => {
    refetch();
  }, [countData, ActivePage]);

  // Create an array of page numbers for pagination
  function createPageArray(pages) {
    const result = [];
    for (let i = 1; i <= pages; i++) {
      result.push(i);
    }
    return result;
  }

  const myPageArray = createPageArray(pageNumber);

  // pagination
  const handlePageNumber = (value) => {
    setActivePage(value);
  };

  // Handle previous page button click
  const handlePreviousPage = () => {
    if (ActivePage > 1) {
      handlePageNumber(ActivePage - 1);
    }
  };
    // delete user api
    const { mutateAsync: deleteMutateAsync } = useDeleteReferral();

  // Handle next page button click
  const handleNextPage = () => {
    if (ActivePage === pageNumber) {
      return;
    } else {
      handlePageNumber(ActivePage + 1);
    }
  };

  const handleDeleteRefferal=(data) => {
    setOpenDeleteModal(false)
    try {
      const response = deleteMutateAsync({
        id: selectedData?._id,
      });
      response.then((res) => refetch()).catch((error) => console.log(error));

    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div>
      <SideBarWrapper header={"Referrals"}>
        <div className="row mt-26 gy-4">
          <div className="col-lg-12">
            <div className="table-header">
              <div className="row gy-3">
                <div className="col-md-6">
                  <div className="filter-section">
                    <div className="select-blk">
                      <select
                        className="form-select"
                        onChange={(e) => setCountData(e.target.value)}>
                        {tableOption?.map((data) => (
                          <option key={data?.id}>{data?.page}</option>
                        ))}
                      </select>
                    </div>
                    <div className="search-blk p-relative">
                      <span className="search-icon">
                        <img
                          src="/assets/images/icon/search.svg"
                          alt="Search"
                        />
                      </span>
                      <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                            setTimeout(() => {
                              refetch();
                            }, 500);
                          }}
                        />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="table-btn-section">
                    <button
                      className="secondary-btn me-3"
                     onClick={()=>setOpenModal(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={15}
                        height={16}
                        viewBox="0 0 15 16"
                        fill="none">
                        <path
                          d="M7.5 15.5C6.01664 15.5 4.56659 15.0601 3.33323 14.236C2.09986 13.4119 1.13856 12.2406 0.570907 10.8701C0.00324964 9.49968 -0.145275 7.99168 0.144114 6.53683C0.433503 5.08197 1.14781 3.7456 2.1967 2.6967C3.2456 1.64781 4.58197 0.933503 6.03683 0.644114C7.49168 0.354725 8.99968 0.50325 10.3701 1.07091C11.7406 1.63856 12.9119 2.59986 13.736 3.83323C14.5601 5.06659 15 6.51664 15 8C14.9979 9.98847 14.207 11.8949 12.8009 13.3009C11.3949 14.707 9.48847 15.4979 7.5 15.5ZM7.5 1.75C6.26387 1.75 5.0555 2.11656 4.02769 2.80332C2.99988 3.49008 2.1988 4.46619 1.72576 5.60823C1.25271 6.75027 1.12894 8.00694 1.3701 9.21932C1.61125 10.4317 2.20651 11.5453 3.08059 12.4194C3.95466 13.2935 5.06831 13.8888 6.28069 14.1299C7.49307 14.3711 8.74974 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23614 13.75 8C13.7482 6.34296 13.0891 4.7543 11.9174 3.5826C10.7457 2.41089 9.15705 1.75182 7.5 1.75Z"
                          fill="#718096"
                        />
                        <path
                          d="M8.125 4.25H6.875V11.75H8.125V4.25Z"
                          fill="#718096"
                        />
                        <path
                          d="M11.25 7.375H3.75V8.625H11.25V7.375Z"
                          fill="#718096"
                        />
                      </svg>
                      Add new referrals
                    </button>
                    {/* <button className="secondary-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={16}
                        viewBox="0 0 14 16"
                        fill="none">
                        <path
                          d="M13.4909 2.96062H10.7203V1.12844C10.7203 0.991685 10.666 0.860533 10.5693 0.763835C10.4726 0.667137 10.3414 0.612812 10.2047 0.612812H4.45031C4.31356 0.612812 4.18241 0.667137 4.08571 0.763835C3.98901 0.860533 3.93469 0.991685 3.93469 1.12844V2.96062H1.16406C1.02731 2.96062 0.896159 3.01495 0.799461 3.11165C0.702762 3.20835 0.648438 3.3395 0.648438 3.47625C0.648438 3.613 0.702762 3.74415 0.799461 3.84085C0.896159 3.93755 1.02731 3.99187 1.16406 3.99187H1.8275V13.5034C1.82841 14.0034 2.02764 14.4825 2.38146 14.8357C2.73528 15.1888 3.21477 15.3872 3.71469 15.3872H10.9678C11.463 15.38 11.9354 15.1784 12.2833 14.826C12.6312 14.4736 12.8267 13.9986 12.8275 13.5034V3.99187H13.4772C13.6139 3.9937 13.7458 3.94112 13.8438 3.84571C13.9418 3.7503 13.9979 3.61988 13.9997 3.48312C14.0015 3.34637 13.9489 3.2145 13.8535 3.11651C13.7581 3.01852 13.6277 2.96245 13.4909 2.96062ZM4.96594 1.64406H9.68906V2.96062H4.96594V1.64406ZM11.7963 13.5034C11.7953 13.7298 11.7048 13.9467 11.5443 14.1065C11.3839 14.2662 11.1667 14.3559 10.9403 14.3559H3.70094C3.47691 14.3523 3.26323 14.261 3.10577 14.1017C2.94831 13.9423 2.85962 13.7275 2.85875 13.5034V3.99187H11.7963V13.5034Z"
                          fill="#718096"
                        />
                        <path
                          d="M5.95264 5.89625C5.81588 5.89625 5.68473 5.95057 5.58804 6.04727C5.49134 6.14397 5.43701 6.27512 5.43701 6.41187V11.9428C5.43701 12.0796 5.49134 12.2107 5.58804 12.3074C5.68473 12.4041 5.81588 12.4584 5.95264 12.4584C6.08939 12.4584 6.22054 12.4041 6.31724 12.3074C6.41394 12.2107 6.46826 12.0796 6.46826 11.9428V6.41187C6.46826 6.27512 6.41394 6.14397 6.31724 6.04727C6.22054 5.95057 6.08939 5.89625 5.95264 5.89625Z"
                          fill="#718096"
                        />
                        <path
                          d="M8.70264 5.89625C8.56588 5.89625 8.43473 5.95057 8.33803 6.04727C8.24134 6.14397 8.18701 6.27512 8.18701 6.41187V11.9428C8.18701 12.0796 8.24134 12.2107 8.33803 12.3074C8.43473 12.4041 8.56588 12.4584 8.70264 12.4584C8.83939 12.4584 8.97054 12.4041 9.06724 12.3074C9.16394 12.2107 9.21826 12.0796 9.21826 11.9428V6.41187C9.21826 6.27512 9.16394 6.14397 9.06724 6.04727C8.97054 5.95057 8.83939 5.89625 8.70264 5.89625Z"
                          fill="#718096"
                        />
                      </svg>
                      Delete referrals
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-main-blk bg-white table-shadow spacer-y24 p-relative">
             {  data?.pageInfo.totalRecords > 0?<table className="table mb-0">
                <thead>
                  <tr>
                    <th>
                      <div className="th-flex">
                        {/* <span className="check-box">
                          <input type="checkbox" id="check0" />
                          <label htmlFor="check0" />
                        </span> */}
                        <span>First Name</span>
                      </div>
                    </th>
                    <th>Last Name</th>
                    <th>Phone Number</th>
                    <th>Note</th>
                    <th>Referred by</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.referralData?.map((items) => (
                    <tr>
                      <td>
                        <div className="td-flex">
                          {/* <span className="check-box">
                            <input type="checkbox" id="check1" />
                            <label htmlFor="check1" />
                          </span> */}
                          <span className="profile-span">
                            {/* <span className="profile-img">
                              <img
                                src="assets/images/temp-images/avatar-2.png"
                                alt
                              />
                            </span> */}
                            <span className="profile-user-name">{items.firstName}</span>
                          </span>
                        </div>
                      </td>
                      <td>{items.lastName}</td>
                      <td>{items.phone}</td>
                      <td>{items.notes}</td>
                      <td>{items.referredBy}</td>
                      <td>
                        <span className="tr-status">
                          <span className={`referrals-status ${items.status === "PENDING" ?"st-pending":"st-approved" } `}>
                            {items.status}
                          </span>
                        </span>
                      </td>
                      <td>
                        <button
                          className="edit-icon-btn"
                          onClick={() => {setOpenDeleteModal(true);setSelectedData(items)}}
                         
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={16}
                            viewBox="0 0 14 16"
                            fill="none">
                            <path
                              d="M13.4909 2.96062H10.7203V1.12844C10.7203 0.991685 10.666 0.860533 10.5693 0.763835C10.4726 0.667137 10.3414 0.612812 10.2047 0.612812H4.45031C4.31356 0.612812 4.18241 0.667137 4.08571 0.763835C3.98901 0.860533 3.93469 0.991685 3.93469 1.12844V2.96062H1.16406C1.02731 2.96062 0.896159 3.01495 0.799461 3.11165C0.702762 3.20835 0.648438 3.3395 0.648438 3.47625C0.648438 3.613 0.702762 3.74415 0.799461 3.84085C0.896159 3.93755 1.02731 3.99187 1.16406 3.99187H1.8275V13.5034C1.82841 14.0034 2.02764 14.4825 2.38146 14.8357C2.73528 15.1888 3.21477 15.3872 3.71469 15.3872H10.9678C11.463 15.38 11.9354 15.1784 12.2833 14.826C12.6312 14.4736 12.8267 13.9986 12.8275 13.5034V3.99187H13.4772C13.6139 3.9937 13.7458 3.94112 13.8438 3.84571C13.9418 3.7503 13.9979 3.61988 13.9997 3.48312C14.0015 3.34637 13.9489 3.2145 13.8535 3.11651C13.7581 3.01852 13.6277 2.96245 13.4909 2.96062ZM4.96594 1.64406H9.68906V2.96062H4.96594V1.64406ZM11.7963 13.5034C11.7953 13.7298 11.7048 13.9467 11.5443 14.1065C11.3839 14.2662 11.1667 14.3559 10.9403 14.3559H3.70094C3.47691 14.3523 3.26323 14.261 3.10577 14.1017C2.94831 13.9423 2.85962 13.7275 2.85875 13.5034V3.99187H11.7963V13.5034Z"
                              fill="#718096"
                            />
                            <path
                              d="M5.95264 5.89625C5.81588 5.89625 5.68473 5.95057 5.58804 6.04727C5.49134 6.14397 5.43701 6.27512 5.43701 6.41187V11.9428C5.43701 12.0796 5.49134 12.2107 5.58804 12.3074C5.68473 12.4041 5.81588 12.4584 5.95264 12.4584C6.08939 12.4584 6.22054 12.4041 6.31724 12.3074C6.41394 12.2107 6.46826 12.0796 6.46826 11.9428V6.41187C6.46826 6.27512 6.41394 6.14397 6.31724 6.04727C6.22054 5.95057 6.08939 5.89625 5.95264 5.89625Z"
                              fill="#718096"
                            />
                            <path
                              d="M8.70264 5.89625C8.56588 5.89625 8.43473 5.95057 8.33803 6.04727C8.24134 6.14397 8.18701 6.27512 8.18701 6.41187V11.9428C8.18701 12.0796 8.24134 12.2107 8.33803 12.3074C8.43473 12.4041 8.56588 12.4584 8.70264 12.4584C8.83939 12.4584 8.97054 12.4041 9.06724 12.3074C9.16394 12.2107 9.21826 12.0796 9.21826 11.9428V6.41187C9.21826 6.27512 9.16394 6.14397 9.06724 6.04727C8.97054 5.95057 8.83939 5.89625 8.70264 5.89625Z"
                              fill="#718096"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> :<div style={{width:"100%", display:"flex", justifyContent:"center"}}><img src="assets/images/temp-images/no record found.png" height="100px" width="200px" alt=""/></div>}
              <div className="table-footer">
                <div className="row gy-2 align-items-center">
                  <div className="col-md-4">
                    <div className="showing-result-blk">
                    <p className="mb-0">
                       {data?.pageInfo.totalRecords > 0 ? `Showing 1 to ${countData} of ${data?.pageInfo.totalRecords} entries`: ""}
                     </p>

                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="pagination-blk">
                      <ul className="pagination mb-0 justify-content-sm-end">
                        <li className="page-item">
                          <a className="page-link" onClick={handlePreviousPage}>
                          {data?.pageInfo.totalRecords > 0 ?<i className="fa-solid fa-chevron-left" />:""}
                          </a>
                        </li>
                        {myPageArray?.map((data) => (
                          <li key={data.id} className="page-item">
                            <a
                              onClick={() => handlePageNumber(data)}
                              className={
                                ActivePage === data
                                  ? "page-link active"
                                  : "page-link "
                              }>
                              {data}
                            </a>
                          </li>
                        ))}
                        <li className="page-item">
                          <a className="page-link" onClick={handleNextPage}>
                          {data?.pageInfo.totalRecords > 0 ?<i className="fa-solid fa-chevron-right" />:""}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarWrapper>
      <AddRaferralModal userData={userData}  openModal={openModal}
        setOpenModal={setOpenModal} refetch={refetch}/>

      {/* <div
        className="modal fade common-modal"
        id="addnewReferral"
        tabIndex={-1}
        aria-labelledby="addnewReferralLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="common-form-wrapper">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="modal-header-blk">
                  <h2>Add New Referrals</h2>
                </div>
                <form>
                  <div className="common-form-fields">
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="common-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Notes"
                      />
                    </div>
                   
                      <div className="common-fields">
                      <select className="form-select">
                        <option>Status</option>
                        <option>PENDING</option>
                        <option>ACCEPTED</option>
                     
                      </select>
                    </div>
                    <div className="common-fields">
                      <select className="form-select">
                        <option>Attribute Referrals to</option>
                        {userData?.users?.map((data) => (<option>{data?.firstName}</option>))}
                      </select>
                    </div>
                    <div className="common-fields">
                      <select className="form-select">
                        <option>Coin rewards</option>
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                      </select>
                    </div>
                  </div>
                  <div className="common-form-button">
                    <button className="primary-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <DeleteModal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} handleDelete={handleDeleteRefferal}/>
    </div>
  );
};

export default Referals;
