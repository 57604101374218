import { useFormik } from "formik";
import React from "react";
import { useNewUser, useRolesTypeList } from "../hooks/api/getdata.hooks";

const DeleteModal = ({ openModal, setOpenModal,handleDelete }) => {

  return (
    <div
      className={
        openModal
          ? "modal fade common-modal show d-block "
          : "modal fade common-modal d-none "
      }
      id="addnewUser"
      tabIndex={-1}
      aria-labelledby="addnewUserLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="common-form-wrapper">
              <button
                type="button"
                className="btn-close"
             
                onClick={() => setOpenModal(false)}
              />
              <div className="modal-header-blk">
                <h2>Are you sure to delete user</h2>
              </div>
           
               <div className="common-form-button" >
               <button
                    // disabled={isRegisterLoading}
                    onClick={()=>setOpenModal(false)}
                    type="submit"
                    className="primary-btn me-2">
                    Cancel
                  </button>
                  <button
                    // disabled={isRegisterLoading}
                    onClick={handleDelete}
                    type="submit"
                    className="primary-btn">
                    Delete
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
