import { useFormik } from "formik";
import React from "react";
import {
  initialValuesRegister,
  validationSchemaRegister,
} from "../validations/schema";
import { useNewUser, useRolesTypeList } from "../hooks/api/getdata.hooks";
import { NumbersOnly } from "../utils/helpers";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const Addusermodal = ({ openModal, setOpenModal, refetch }) => {
  const { mutateAsync: registerMutateAsync, isLoading: isRegisterLoading } =
    useNewUser();
  const { data: getRolesData } = useRolesTypeList();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValuesRegister,
    validationSchema: validationSchemaRegister,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await registerMutateAsync({
          email: values?.email,
          password: values?.password,
          firstName: values?.firstName,
          lastName: values?.lastName,
          phone: values?.phoneNumber,
          role: values?.accountType,
        });

        if (response) {
          refetch();
          resetForm();
          setOpenModal(false);
          toast.success(response?.message);
        }
      } catch (error) {
        console.log("err");
      }
    },
  });
  return (
    <div
      className={
        openModal
          ? "modal fade common-modal show d-block "
          : "modal fade common-modal d-none "
      }
      id="addnewUser"
      tabIndex={-1}
      aria-labelledby="addnewUserLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="common-form-wrapper">
              <button
                type="button"
                className="btn-close"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => setOpenModal(false)}
              />
              <div className="modal-header-blk">
                <h2>Add New User</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="common-form-fields">
                  <div className="common-fields">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.firstName && errors.firstName && (
                    <div class="error">{errors.firstName}</div>
                  )}
                  <div className="common-fields">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.lastName && errors.lastName && (
                    <div class="error">{errors.lastName}</div>
                  )}
                  <div className="common-fields">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.email && errors.email && (
                    <div class="error">{errors.email}</div>
                  )}
                  <div className="common-fields">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.password && errors.password && (
                    <div class="error">{errors.password}</div>
                  )}
                  <div className="common-fields">
                    <input
                      onKeyPress={NumbersOnly}
                      type="text"
                      className="form-control"
                      placeholder="phone number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.phoneNumber && errors.phoneNumber && (
                    <div class="error">{errors.phoneNumber}</div>
                  )}
                  <div className="common-fields">
                    <select
                      className="form-select"
                      name="accountType"
                      onChange={handleChange}>
                      <option>Account Type</option>
                      {getRolesData?.map((item) => {
                        return <option>{item}</option>;
                      })}
                    </select>
                  </div>
                  {touched.accountType && errors.accountType && (
                    <div class="error">{errors.accountType}</div>
                  )}
                </div>
                <div className="common-form-button">
                  <button
                    disabled={isRegisterLoading}
                    type="submit"
                    className="primary-btn">
                    {isRegisterLoading ? <ClipLoader size={15} /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addusermodal;
