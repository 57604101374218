import React, { createContext, useContext, useEffect, useState } from 'react';

const SideBarContext = createContext(null);


export const SideBarProvider = ({ children }) => {
    const [openSidebar, setOpenSidebar] = useState(false);

    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setOpenSidebar(true);
            }
            else {
                setOpenSidebar(false)
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <SideBarContext.Provider value={{ openSidebar, toggleSidebar }}>{children}</SideBarContext.Provider>

};

export const useSideBarContext = () => useContext(SideBarContext);